* {
  margin: 0%;
  padding: 0%;
  font-family: "Open Sans", sans-serif;
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,300&display=swap");
nav {
  background-color: rgb(76, 109, 173);
  color: white;
  padding: 20px;
  text-align: center;
}
body {
  background-color: rgb(219, 229, 231);
}
.container {
  background-color: rgb(51 76 126);
  width: 70%;
  margin: auto;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 4vmax 8vmax;
}
.container > form {
  display: flex;
  flex-direction: column;
}
input,
textarea,
button {
  padding: 15px;
  border: 1px solid black;
  border-radius: 5px;
  margin: 10px 0px;
  font-size: 1.4rem;
}
.container > h1 {
  text-align: center;
  margin: 30px;
  font-size: 2.5rem;
}
button {
  border: none;
  cursor: pointer;
  background-color: rgb(43, 39, 40);
  color: aliceblue;
}

.task {
  display: flex;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  margin: 20px;
  align-items: center;
}
.task > div {
  width: 100%;
}
.task button {
  border-radius: 100%;
  width: 30px;
  height: 30px;
  font-size: 1.6rem;
  background-color: crimson;
  display: flex;
  justify-content: center;
  align-items: center;
}
.task button:hover {
  background-color: crimson;
}
